exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-and-talks-js": () => import("./../../../src/pages/articles-and-talks.js" /* webpackChunkName: "component---src-pages-articles-and-talks-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-systems-js": () => import("./../../../src/pages/design-systems.js" /* webpackChunkName: "component---src-pages-design-systems-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-audit-js": () => import("./../../../src/pages/services/audit.js" /* webpackChunkName: "component---src-pages-services-audit-js" */),
  "component---src-pages-services-consultancy-js": () => import("./../../../src/pages/services/consultancy.js" /* webpackChunkName: "component---src-pages-services-consultancy-js" */),
  "component---src-pages-services-development-js": () => import("./../../../src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-speaking-js": () => import("./../../../src/pages/services/speaking.js" /* webpackChunkName: "component---src-pages-services-speaking-js" */),
  "component---src-pages-services-strategy-js": () => import("./../../../src/pages/services/strategy.js" /* webpackChunkName: "component---src-pages-services-strategy-js" */),
  "component---src-pages-services-supervision-js": () => import("./../../../src/pages/services/supervision.js" /* webpackChunkName: "component---src-pages-services-supervision-js" */),
  "component---src-pages-services-workshops-js": () => import("./../../../src/pages/services/workshops.js" /* webpackChunkName: "component---src-pages-services-workshops-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-aligning-ux-designers-and-ui-developers-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/design-systems/aligning-ux-designers-and-ui-developers/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-aligning-ux-designers-and-ui-developers-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-boosting-workshop-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/design-systems/boosting-workshop/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-boosting-workshop-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-design-systems-101-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/design-systems/design-systems-101/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-design-systems-101-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-gems-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/design-systems/gems/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-gems-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-hands-on-workshop-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/design-systems/hands-on-workshop/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-hands-on-workshop-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-pattern-journey-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/design-systems/pattern-journey/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-pattern-journey-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-team-process-workshop-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/design-systems/team-process-workshop/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-team-process-workshop-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-what-is-a-design-system-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/design-systems/what-is-a-design-system/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-design-systems-what-is-a-design-system-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-automating-style-guide-driven-development-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/automating-style-guide-driven-development/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-automating-style-guide-driven-development-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-bem-faq-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/bem-faq/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-bem-faq-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-beml-html-preprocessor-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/beml-html-preprocessor/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-beml-html-preprocessor-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-best-way-to-hide-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/best-way-to-hide/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-best-way-to-hide-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-blocks-and-bundles-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/blocks-and-bundles/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-blocks-and-bundles-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-borschik-static-assets-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/borschik-static-assets/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-borschik-static-assets-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-business-models-innovation-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/business-models-innovation/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-business-models-innovation-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-communication-culture-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/communication-culture/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-communication-culture-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-dealing-atomically-with-icon-fonts-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/dealing-atomically-with-icon-fonts/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-dealing-atomically-with-icon-fonts-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-system-remote-work-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/design-system-remote-work/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-system-remote-work-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-system-versioning-basics-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/design-system-versioning-basics/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-system-versioning-basics-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-systems-career-path-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/design-systems-career-path/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-systems-career-path-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-systems-review-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/design-systems-review/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-systems-review-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-tokens-and-components-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/design-tokens-and-components/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-design-tokens-and-components-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-designers-developers-linkedin-study-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/designers-developers-linkedin-study/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-designers-developers-linkedin-study-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-docpad-with-bem-taste-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/docpad-with-bem-taste/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-docpad-with-bem-taste-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-dotfiles-and-configurations-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/dotfiles-and-configurations/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-dotfiles-and-configurations-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-flatten-array-with-reduce-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/flatten-array-with-reduce/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-flatten-array-with-reduce-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-fonts-no-cramp-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/fonts-no-cramp/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-fonts-no-cramp-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-frontend-babel-alpha-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/frontend-babel-alpha/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-frontend-babel-alpha-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-git-multiple-emails-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/git-multiple-emails/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-git-multiple-emails-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-html-5-mobile-app-width-native-ui-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/html5-mobile-app-width-native-ui/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-html-5-mobile-app-width-native-ui-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-image-diffs-with-git-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/image-diffs-with-git/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-image-diffs-with-git-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-javascript-component-solutions-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/javascript-component-solutions/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-javascript-component-solutions-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-javascript-in-bemjson-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/javascript-in-bemjson/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-javascript-in-bemjson-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-jira-hypothesis-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/jira-hypothesis/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-jira-hypothesis-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-jscs-in-use-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/jscs-in-use/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-jscs-in-use-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-maintaining-design-systems-with-proper-user-research-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/maintaining-design-systems-with-proper-user-research/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-maintaining-design-systems-with-proper-user-research-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-new-frontend-methodology-bem-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/new-frontend-methodology-bem/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-new-frontend-methodology-bem-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-on-the-unicorn-law-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/on-the-unicorn-law/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-on-the-unicorn-law-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-patterned-triangle-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/patterned-triangle/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-patterned-triangle-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-platforms-create-value-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/platforms-create-value/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-platforms-create-value-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-ppl-centric-design-systems-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/ppl-centric-design-systems/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-ppl-centric-design-systems-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-proptypes-in-zeroheight-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/proptypes-in-zeroheight/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-proptypes-in-zeroheight-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-pseudo-tag-cloud-css-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/pseudo-tag-cloud-css/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-pseudo-tag-cloud-css-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-publish-packages-on-github-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/publish-packages-on-github/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-publish-packages-on-github-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-pulling-the-contributions-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/pulling-the-contributions/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-pulling-the-contributions-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-quick-start-bem-stack-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/quick-start-bem-stack/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-quick-start-bem-stack-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-raising-new-devs-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/raising-new-devs/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-raising-new-devs-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-react-angular-project-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/react-angular-project/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-react-angular-project-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-react-hackathon-materials-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/react-hackathon-materials/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-react-hackathon-materials-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-react-redux-simple-project-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/react-redux-simple-project/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-react-redux-simple-project-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-release-to-npm-with-github-actions-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/release-to-npm-with-github-actions/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-release-to-npm-with-github-actions-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-run-app-in-time-on-mac-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/run-app-in-time-on-mac/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-run-app-in-time-on-mac-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-sc-5-style-guide-next-level-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/sc5-style-guide-next-level/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-sc-5-style-guide-next-level-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-sc-5-styleguide-for-smallers-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/sc5-styleguide-for-smallers/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-sc-5-styleguide-for-smallers-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-shared-project-vision-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/shared-project-vision/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-shared-project-vision-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-steroids-drawers-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/steroids-drawers/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-steroids-drawers-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-stories-with-different-viewports-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/stories-with-different-viewports/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-stories-with-different-viewports-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-switching-to-bem-core-2-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/switching-to-bem-core-2/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-switching-to-bem-core-2-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-testpost-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/testpost/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-testpost-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-to-bem-or-not-to-bem-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/to-bem-or-not-to-bem/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-to-bem-or-not-to-bem-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-tutorial-javascript-components-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/tutorial-javascript-components/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-tutorial-javascript-components-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-webfonts-with-sass-and-webpack-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/webfonts-with-sass-and-webpack/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-webfonts-with-sass-and-webpack-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-week-44-2016-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/week-44-2016/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-week-44-2016-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-what-makes-entrepreneurs-entrepreneurial-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/what-makes-entrepreneurs-entrepreneurial/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-what-makes-entrepreneurs-entrepreneurial-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-ym-modular-system-index-en-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/posts/ym-modular-system/index_en.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-posts-ym-modular-system-index-en-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-appomni-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/appomni/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-appomni-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-bem-project-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/bem-project/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-bem-project-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-business-finland-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/business-finland/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-business-finland-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-elisa-design-system-v-1-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/elisa-design-system-v1/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-elisa-design-system-v-1-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-elisa-renewal-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/elisa-renewal/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-elisa-renewal-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-f-secure-ds-strategy-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/f-secure-ds-strategy/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-f-secure-ds-strategy-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-hands-on-design-system-workshop-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/hands-on-design-system-workshop/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-hands-on-design-system-workshop-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-hands-on-with-design-systems-2021-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/hands-on-with-design-systems-2021/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-hands-on-with-design-systems-2021-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-hands-on-with-design-systems-2021-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/hands-on-with-design-systems-2021.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-hands-on-with-design-systems-2021-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-huhtamaki-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/huhtamaki/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-huhtamaki-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-lego-project-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/lego-project/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-lego-project-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-manychat-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/manychat/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-manychat-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-metro-design-system-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/metro-design-system/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-metro-design-system-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-react-finland-2020-workshop-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/react-finland-2020-workshop/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-react-finland-2020-workshop-index-md" */),
  "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-yara-index-md": () => import("./../../../src/templates/Post.js?__contentFilePath=/home/runner/work/varya.github.com/varya.github.com/content/projects/yara/index.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-runner-work-varya-github-com-varya-github-com-content-projects-yara-index-md" */),
  "component---src-templates-tag-index-js": () => import("./../../../src/templates/TagIndex.js" /* webpackChunkName: "component---src-templates-tag-index-js" */)
}

